<template>
    <div
        class="app-sms-balance"
        @click="_fetchBalance"
    >
        <div class="app-sms-balance__icon">
            <i class="fas fa-credit-card fa-lg" />
        </div>
        <div
            v-if="loading"
            class="app-sms-balance__loading"
        >
            Загрузка...
        </div>
        <div
            v-else
            class="app-sms-balance__info"
        >
            <div
                v-for="(item, index) in data"
                :key="index"
                class="app-sms-balance__row"
            >
                {{ item.name }} — {{ item.balance }} {{ item.type }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'SmsBalance',
    data() {
        return {
            channelName: 'SmsBalance',
            infoMessageEvent: 'SmsBalanceEcho',
            subscriptionFn: undefined
        };
    },
    computed: {
        ...mapGetters('smsBalance', [
            'data',
            'loading'
        ])
    },
    mounted() {
        this.setLoading({ loading: false });
        this._subscribeFetchOverTime();
        this.$echo.private(this.channelName)
            .listen(this.infoMessageEvent, ({ balance }) => {
                this.setData({ data: balance });
                this.setLoading({ loading: false });
            });
    },
    destroyed() {
        this.$echo.leave(this.channelName);
        clearInterval(this.subscriptionFn);
    },
    methods: {
        ...mapMutations('smsBalance', [
            'setData',
            'setLoading'
        ]),
        ...mapActions('smsBalance', [
            'getSmsBalance'
        ]),

        _subscribeFetchOverTime() {
            this.subscriptionFn = setInterval(() => {
                this._fetchBalance();
            }, 60000);
        },
        _fetchBalance() {
            return this.getSmsBalance()
                .catch(() => this.$message.error('Во время получения данных произошла ошибка'));
        }
    }
};
</script>

<style lang="scss">
.app-sms-balance {
    display: flex;
    height: 60px;
    margin: 0 20px;

    &__icon {
        i {
            color: #fff !important;
        }
    }

    &__info-container {
        display: flex;
        flex-direction: column;
    }

    &__loading {
        padding: 5px 0;
        font-family: "PT Sans", sans-serif;
        font-size: 12px;
        line-height: 10px;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
    }

    &__row {
        font-family: "PT Sans", sans-serif;
        font-size: 12px;
        line-height: 10px;
        padding: 5px 0;
        text-decoration: none;
    }
}
</style>
