<template>
    <div class="app-sidebar">
        <el-menu
            :collapse="isCollapsed"
            @select="select"
        >
            <div
                v-for="item in menu"
                :key="item.route"
            >
                <el-submenu
                    v-if="item.sub && item.sub.length"
                    :index="item.title"
                >
                    <template slot="title">
                        <i :class="item.icon" />
                        <span v-show="!isCollapsed">{{ item.title }}</span>
                    </template>
                    <el-menu-item
                        v-for="sub in item.sub"
                        :key="sub.route"
                        :index="sub.route"
                    >
                        <template slot="title">
                            <i
                                v-if="sub.icon"
                                :class="sub.icon"
                            />
                            {{ sub.title }}
                        </template>
                    </el-menu-item>
                </el-submenu>
                <el-menu-item
                    v-else
                    :index="item.route"
                >
                    <i :class="item.icon" />
                    <span slot="title">{{ item.title }}</span>
                </el-menu-item>
            </div>
        </el-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ROUTES } from '@/enums';

export default {
    name: 'AppSidebar',

    computed: {
        ...mapState('sidebar', [
            'isCollapsed'
        ]),

        menu() {
            return [
                {
                    title: 'Главная',
                    route: ROUTES.HOME,
                    icon: 'fas fa-th-large'
                },

                {
                    title: 'События',
                    icon: 'fas fa-calendar-alt',
                    sub: [
                        {
                            title: 'Список событий',
                            route: ROUTES.EVENT.LIST
                        },
                        {
                            title: 'Добавить событие',
                            route: ROUTES.EVENT.ADD
                        },
                        {
                            title: 'Список онлайн-событий',
                            route: ROUTES.ONLINE_EVENT.LIST
                        },
                        {
                            title: 'Добавить онлайн-событие',
                            route: ROUTES.ONLINE_EVENT.ADD
                        }
                    ]
                },
                {
                    title: 'Трансфер',
                    icon: 'el-icon-guide',
                    sub: [
                        {
                            title: 'Список мест прибытия\\убытия',
                            route: ROUTES.TRANSFER.TRANSFER_PLACES.LIST
                        },
                        {
                            title: 'Добавить место',
                            route: ROUTES.TRANSFER.TRANSFER_PLACES.ADD
                        }
                    ]
                },

                {
                    title: 'Отели',
                    icon: 'fas fa-hotel',
                    sub: [
                        {
                            title: 'Список отелей',
                            route: ROUTES.HOTEL.LIST
                        },
                        {
                            title: 'Добавить отель',
                            route: ROUTES.HOTEL.ADD
                        }
                    ]
                },

                {
                    title: 'Пользователи',
                    icon: 'fas fa-user',
                    sub: [
                        {
                            title: 'Список пользователей',
                            route: ROUTES.MANAGER.LIST
                        },
                        {
                            title: 'Добавить пользователя',
                            route: ROUTES.MANAGER.ADD
                        }
                    ]
                },
                {
                    title: 'Гости',
                    icon: 'fas fa-users',
                    sub: [
                        {
                            title: 'Статусы гостей',
                            route: ROUTES.PARTICIPANT.STATUSES
                        },
                        {
                            title: 'Ресепшен',
                            route: ROUTES.RECEPTION.DASHBOARD
                        }
                    ]
                },

                {
                    title: 'Услуги',
                    icon: 'fas fa-list',
                    sub: [
                        {
                            title: 'Список услуг',
                            route: ROUTES.SERVICE.LIST
                        },
                        {
                            title: 'Специалисты',
                            route: ROUTES.SPECIALIST.LIST
                        }
                    ]
                },

                {
                    title: 'Очередь',
                    icon: 'fas fa-desktop',
                    sub: [
                        {
                            title: 'Настройка очереди',
                            route: ROUTES.E_QUEUE.CONFIG
                        },
                        {
                            title: 'Экран очереди',
                            route: ROUTES.E_QUEUE.SCREEN
                        }
                    ]
                },

                {
                    title: 'Уведомления',
                    icon: 'fas fa-bell',
                    sub: [
                        {
                            title: 'СМС на трансфер',
                            route: ROUTES.NOTIFICATIONS.TRANSFER_REGISTRATION
                        }
                    ]
                },

                {
                    title: 'Выйти',
                    route: ROUTES.AUTH.LOGIN,
                    params: { logoutMe: true },
                    icon: 'fas fa-sign-in-alt'
                }
            ];
        }
    },

    methods: {
        select(name) {
            const predicate = x => x.route === name;
            let item = this.menu.find(x => {
                return predicate(x) || (x.sub && x.sub.find(predicate));
            });
            if (item && item.route !== name) {
                item = item.sub.find(predicate);
            }
            this.$router.push({
                name: item.route,
                params: item.params ? item.params : {}
            });
        }
    }
};
</script>

<style lang="scss">
.app-sidebar {
    height: 100%;

    .el-menu {
        height: 100%;
    }

    .el-submenu__icon-arrow {
        right: 5px;
        top: 55%;
    }

    .fas {
        text-align: center;
        margin-right: 5px;
        width: 24px;
    }
}
</style>
