<template>
    <div class="app-before-content">
        <app-breadcrumbs />
        <br>
        <br>
    </div>
</template>

<script>
import AppBreadcrumbs from './AppBreadcrumbs';

export default {
    name: 'AppBeforeContent',

    components: {
        AppBreadcrumbs
    },

    methods: {}
};
</script>
