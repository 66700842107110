<template>
    <div class="app-layout">
        <el-container>
            <el-header>
                <app-header />
            </el-header>
            <el-container>
                <el-aside :width="width">
                    <app-sidebar />
                </el-aside>
                <el-main>
                    <app-before-content />
                    <slot name="content" />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AppHeader from '@/components/AppHeader';
import AppSidebar from '@/components/AppSidebar';
import AppBeforeContent from '@/components/AppBeforeContent';

export default {
    name: 'Layout',

    components: {
        AppHeader,
        AppSidebar,
        AppBeforeContent
    },

    computed: {
        ...mapState('sidebar', [
            'isCollapsed'
        ]),

        width() {
            return this.isCollapsed ? '65px' : '300px';
        }
    }
};
</script>

<style lang="scss">
    .app-layout {
        height: 100%;

        .el-header {
            padding: 0;
        }

        & > .el-container {
            height: 100%;
        }
    }
</style>
