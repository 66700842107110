<template>
    <div class="app-breadcrumbs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                v-for="item in preparedBreadcrumbs"
                :key="item.title"
                :to="{ name: item.route, params: item.params ? item.params : {} }"
            >
                {{ item.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'AppBreadcrumbs',

    computed: {
        ...mapState([
            'breadcrumbs'
        ]),

        preparedBreadcrumbs() {
            return this.breadcrumbs
                .map(x => {
                    if (Array.isArray(x)) {
                        return {
                            title: x[0],
                            route: x[1] ? x[1] : null,
                            params: x[2] ? x[2] : {}
                        };
                    }
                    return x;
                });
        }
    }
};
</script>
