<template>
    <div class="app-header">
        <el-menu
            class="el-menu-demo"
            mode="horizontal"
            background-color="#409EFF"
            text-color="#fff"
            active-text-color="#ffd95a"
            @select="select"
        >
            <el-menu-item
                index="collapse"
                style="width: 65px;"
            >
                <i
                    :class="{'el-icon-d-arrow-left': !isCollapsed, 'el-icon-d-arrow-right': isCollapsed }"
                    style="color: #fff;"
                />
            </el-menu-item>
            <el-menu-item
                index="callCustumer"
                class="e-queue-menu-item"
                @click="_onEQueueClickBtn"
            >
                <i class="fas fa-bell" />
            </el-menu-item>
            <el-menu-item
                index="managerMenu"
                class="manager-menu-item"
            >
                <sms-balance />
                <span style="text-decoration: underline;">{{ managerFullname }}</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
import { EQueue } from '@/api';
import { mapState, mapMutations } from 'vuex';
import { ROUTES } from '@/enums';
import SmsBalance from '@/containers/common/smsBalance/SmsBalance';

export default {
    name: 'AppHeader',
    components: { SmsBalance },
    data() {
        return {};
    },

    computed: {
        ...mapState('sidebar', [
            'isCollapsed'
        ]),

        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('auth', [
            'manager'
        ]),

        managerFullname() {
            return this.$prop('manager.user.fullname');
        },

        menu() {
            if (!this.event) {
                return [];
            }

            /** @type {EventResource} */
            const event = this.event;

            return [
                {
                    title: event.attributes.title,
                    route: ROUTES.HOME, // todo
                    sub: [
                        {
                            title: 'Добавить новое событие',
                            route: ROUTES.EVENT.ADD
                        },
                        {
                            title: 'Список событий',
                            route: ROUTES.EVENT.LIST
                        }
                    ]
                }
            ];
        }
    },

    mounted() {
        this._setupCollapsibleMenuOnLoad();
    },

    methods: {
        ...mapMutations('sidebar', [
            'setIsCollapsed'
        ]),

        select(name) {
            if (name === 'collapse') {
                return this.toggleCollapse();
            }
        },

        toggleCollapse() {
            this.$storage.put('isSidebarCollapsed', !this.isCollapsed);
            this.setIsCollapsed(!this.isCollapsed);
        },

        _setupCollapsibleMenuOnLoad() {
            const isCollapsedData = this.$storage.get('isSidebarCollapsed');
            const isCollapsed = isCollapsedData && isCollapsedData.data !== null
                ? !!isCollapsedData.data
                : this.isCollapsed;
            this.setIsCollapsed(isCollapsed);
        },
        _onEQueueClickBtn: _.debounce(function() {
            this._callCustomer();
        }, 1000),

        _callCustomer() {
            EQueue.Screen.callCustomer()
                .then((response) => {
                    this.$message.info('Вызван номер: ' + _.get(response, 'attributes.number'));
                })
                .catch((e) => {
                    this.$message.error('Не удалось вызвать человека');
                });
        }

    }
};
</script>
<style lang="scss">
.el-menu-demo {
    display: flex;
}

.el-menu-demo.el-menu--horizontal {
    .manager-menu-item {
        margin-left: auto;
        font-size: 18px;
        //text-decoration: underline;
        display: flex;
    }

    .e-queue-menu-item {
        margin-left: 20%;

        i {
            font-size: 24px;
            color: #fff;
        }
    }
}


</style>
